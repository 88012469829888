import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Container, Row, Col, Card, Button, Modal, Spinner } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import NewsForm from './NewsForm';
import { token } from '../../Hooks/UserHooks';
import { APP_CONFIG } from '../../config';

const NewsPage = () => {
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${APP_CONFIG.backendUrl}api/news`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNewsList(response.data.news);
    } catch (error) {
      toast.error('Error fetching news. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this news item?')) {
      try {
        await axios.delete(`${APP_CONFIG.backendUrl}api/news/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('News item deleted successfully');
        fetchNews();
      } catch (error) {
        toast.error('Error deleting news. Please try again later.');
      }
    }
  };

  const handleEdit = (news) => {
    setSelectedNews(news);
    setShowFormModal(true);
  };

  const handleAddNews = () => {
    setSelectedNews(null);
    setShowFormModal(true);
  };

  const handleFormSuccess = () => {
    fetchNews();
    setShowFormModal(false);
  };

  return (
    <Container>
      <h2 className="my-4 text-center">Manage News</h2>
      <Button
        variant="primary"
        onClick={handleAddNews}
        className="mb-4 d-block mx-auto"
        disabled={isLoading}
      >
        Add News
      </Button>
      {isLoading ? (
        <div className="text-center my-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Row style={{ height: '75vh', overflowY: 'auto' }}>
            {newsList.map((news) => (
              <Col key={news._id} sm={12} className="mb-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={news.thumbnail?.url || '/placeholder.png'}
                    alt="News Thumbnail"
                    className="img-fluid"
                    style={{ height: '200px', objectFit: 'contain' }}
                  />
                  <Card.Body>
                    <Card.Title>{news.title}</Card.Title>
                    <Button
                      variant="warning"
                      className="me-2"
                      onClick={() => handleEdit(news)}
                    >
                      <FaEdit /> Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(news._id)}
                    >
                      <FaTrash /> Delete
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      <Modal show={showFormModal} onHide={() => setShowFormModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedNews ? 'Edit News' : 'Add News'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewsForm
            news={selectedNews}
            onSuccess={handleFormSuccess}
            onCancel={() => setShowFormModal(false)}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default NewsPage;
