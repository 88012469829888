import React, { useState } from 'react';
import './leadf.css';
import lfImage from '../../Assets/logo.png';
import axios from 'axios';
import { APP_CONFIG } from '../../config';
import DahsboardLoader from '../DahsboardLoader/DahsboardLoader';

const LeadForm = ({ propname, type }) => {
  const [title, setTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(false);
  const [selectedType, setSelectedType] = useState('English');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [appointmentScheduled, setAppointmentScheduled] = useState(false);
  const [username, setusername] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const currentDate = new Date().toISOString().split('T')[0];

  const [minDate] = useState(currentDate);
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setusername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setphone(event.target.value);
  };

  const handleInputChange = (event) => {
    setTitle(event.target.id);
  };

  const handleTypeChange = (event) => {
    const selectedValue = event.target.getAttribute('data-value');
    setSelectedType(selectedValue);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    if (isDropdownOpen === true) {
      setDropdownOpen(false);
    } else {
      return null;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setDropdownOpen(!isDropdownOpen);
    }
  };

  const handleTypeKeyDown = (event) => {
    if (event.key === 'Enter') {
      const selectedValue = event.currentTarget.getAttribute('data-value');
      setSelectedType(selectedValue);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (!title || !username || !email || !phone || !selectedType) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const data = {
        property_name: propname,
        type_of_inquiry: type,
        title: title,
        name: username,
        email: email,
        phone: phone,
        availability: selectedDate,
        preferred_language: selectedType,
      };
      const response = await axios.post(`${APP_CONFIG.url}api/leadform`, data);
      if (response.data.status === 201) {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      } else if (response.data.status !== 201) {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        setMessage(true);
      }

      setAppointmentScheduled(true);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='l_form_wrapper' onClick={handleClose}>
      {!appointmentScheduled ? (
        <form className='l_form'>
          <img src={lfImage} alt='leadform pic' />
          <label className='label'>
            <i className='fa-regular fa-user'></i>
            <input
              type='text'
              placeholder='Full name'
              onChange={handleUsernameChange}
              value={username}
              id='fullname'
            />
          </label>
          <label className='label'>
            <i className='fa-regular fa-envelope'></i>
            <input
              type='text'
              placeholder='Email address'
              onChange={handleEmailChange}
              value={email}
              id='email'
            />
          </label>
          <label className='label'>
            <i className='fa-solid fa-phone'></i>
            <input
              type='text'
              placeholder='Phone number'
              onChange={handlePhoneChange}
              value={phone}
              id='phone'
            />
          </label>
          <label className='label'>
            <h4>Availability</h4>
            <input
              type='date'
              id='dateInput'
              name='dateInput'
              value={selectedDate}
              onChange={handleDateChange}
              min={minDate} // Set the minimum date
            />
          </label>
          <div className='rb_wrap'>
            <h4 className='rb_title'>Languages</h4>
            <div
              className={
                isDropdownOpen ? 'filtering filtering_open' : 'filtering'
              }
              tabIndex={0}
              onKeyDown={handleKeyDown}
              onClick={handleDropdownToggle}
            >
              <span className='main_span'>
                {selectedType}
                <i className='fa-solid fa-caret-down'></i>
              </span>
              {isDropdownOpen && (
                <ul>
                  <li
                    data-value='English'
                    onClick={handleTypeChange}
                    tabIndex={0}
                    onKeyDown={handleTypeKeyDown}
                  >
                    English
                  </li>
                  <li
                    data-value='Arabic'
                    onClick={handleTypeChange}
                    tabIndex={0}
                    onKeyDown={handleTypeKeyDown}
                  >
                    Arabic
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className='title_container'>
            <h4>what is your title?</h4>
            <div className='titles_wrap'>
              <div className='mr_container'>
                <label htmlFor='Mr.'>Mr.</label>
                <i
                  className='fa-solid fa-person'
                  style={{
                    color: title === 'Mr.' ? 'var(--accent-blue)' : 'black',
                  }}
                ></i>
                <input
                  type='radio'
                  name='title'
                  id='Mr.'
                  onChange={handleInputChange}
                />
              </div>
              <div className='ms_container'>
                <label htmlFor='Ms.'>Ms.</label>
                <i
                  className='fa-solid fa-person-dress'
                  style={{
                    color: title === 'Ms.' ? 'var(--accent-pink)' : 'black',
                  }}
                ></i>
                <input
                  type='radio'
                  name='title'
                  id='Ms.'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <button
            className='submit_button'
            type='submit'
            onClick={(event) => {
              onSubmit(event);
            }}
          >
            Submit
          </button>
        </form>
      ) : (
        <>
          {loader ? (
            <DahsboardLoader />
          ) : (
            <>
              {message ? (
                <p className='inq_sent'>Inquiry has been sent</p>
              ) : (
                <p className='inq_sent'>Inquiry has been not sent</p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LeadForm;
