import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './index.css'; // Ensure this is imported

const ViewNews = () => {
  const [news, setNews] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.news) {
      setNews(location.state.news);
    }
  }, [location]);

  if (!news) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <Container className="mt-5 news-container">
      <Row>
        <Col md={8} className="mx-auto">
          <Card className="news-card1">
            {news.thumbnail?.url && (
              <Card.Img
                variant="top"
                src={news.thumbnail.url}
                alt={news.title}
                className="news-thumbnail img-fluid"
              />
            )}
            <Card.Body className="news-body">
              <Card.Title className="news-title">{news.title}</Card.Title>
              <div
                className="news-description"
                dangerouslySetInnerHTML={{ __html: news.description }}
              />
              <Button variant="primary" className="read-more-btn" onClick={() => window.location.href='/'}>Go Back to Home</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewNews;
