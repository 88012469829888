import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { token } from "../../../Hooks/UserHooks";
import { APP_CONFIG } from '../../../config';
import { toast } from 'react-toastify';
import { Container, Row, Col, Form, Button, Modal, Spinner, Pagination, Card } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';

const Developer = () => {
  const [developers, setDevelopers] = useState([]);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [editingDeveloper, setEditingDeveloper] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDevelopers, setTotalDevelopers] = useState(0);
  const developersPerPage = 10;

  useEffect(() => {
    fetchDevelopers(currentPage);
  }, [currentPage]);

  const fetchDevelopers = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${APP_CONFIG.backendUrl}api/developer?page=${page}&limit=${developersPerPage}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDevelopers(response.data.developers);
      setTotalDevelopers(response.data.totalDevelopersCount);
    } catch (error) {
      console.error('Error fetching developers:', error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Error fetching developers. Please try again.');
      } else {
        toast.error('Network error. Please check your connection or try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEdit = async (e) => {
    e.preventDefault();
    setIsAddingOrEditing(true);
    const formData = new FormData();
    formData.append('name', name);
    if (image) formData.append('image', image);

    try {
      if (editingDeveloper) {
        await axios.put(
          `${APP_CONFIG.backendUrl}api/developer/${editingDeveloper._id}`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success('Developer updated successfully');
      } else {
        await axios.post(`${APP_CONFIG.backendUrl}api/developer`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('Developer added successfully');
      }
      fetchDevelopers(currentPage);
      resetForm();
    } catch (error) {
      console.error('Error saving developer:', error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Error saving developer. Please try again.');
      } else {
        toast.error('Network error. Please check your connection or try again later.');
      }
    } finally {
      setIsAddingOrEditing(false);
    }
  };

  const resetForm = () => {
    setName('');
    setImage(null);
    setEditingDeveloper(null);
    setShowAddEditModal(false);
  };

  const handleEdit = (developer) => {
    setEditingDeveloper(developer);
    setName(developer.name);
    setImage(null);
    setShowAddEditModal(true);
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    if (window.confirm('Are you sure you want to delete this developer?')) {
      try {
        await axios.delete(`${APP_CONFIG.backendUrl}api/developer/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('Developer deleted successfully');
        fetchDevelopers(currentPage);
      } catch (error) {
        console.error('Error deleting developer:', error);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message || 'Error deleting developer. Please try again.');
        } else {
          toast.error('Network error. Please check your connection or try again later.');
        }
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const totalPages = Math.ceil(totalDevelopers / developersPerPage);
  const paginationItems = [];

  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Container>
      <h2 className="my-4 text-center text-primary">Manage Developers</h2>
      <Button
        variant="primary"
        onClick={() => setShowAddEditModal(true)}
        disabled={isLoading || isAddingOrEditing || isDeleting}
        className="mb-4 d-block mx-auto"
      >
        Add Developer
      </Button>
      {isLoading ? (
        <div className="text-center my-4">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Row style={{ height: '75vh', overflowY: 'auto' }}>
            {developers.length > 0 ? (
              developers?.map((developer) => (
                <Col key={developer._id} sm={12} md={6} lg={4} className="mb-4">
                  <Card className="shadow-sm">
                    <Card.Img
                      variant="top"
                      src={developer.logoUrl ? developer.logoUrl.url : '/logo-placeholder-image.png'}
                      alt="Developer Logo"
                      className="img-fluid rounded"
                      style={{ width: '100%', height: '200px', objectFit: 'contain' }}
                    />
                    <Card.Body>
                      <Card.Title>{developer.name}</Card.Title>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEdit(developer)}
                        className="me-2 w-100 mb-2"
                        disabled={isLoading || isAddingOrEditing || isDeleting}
                      >
                        <FaEdit /> Edit
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(developer._id)}
                        className="w-100"
                        disabled={isLoading || isAddingOrEditing || isDeleting}
                      >
                        <FaTrash /> Delete
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col className="text-center">
                <p>No developers found.</p>
              </Col>
            )}
          </Row>

          <Pagination>{paginationItems}</Pagination>
        </>
      )}

      <Modal show={showAddEditModal} onHide={resetForm}>
        <Modal.Header closeButton>
          <Modal.Title>{editingDeveloper ? 'Edit Developer' : 'Add Developer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddEdit}>
            <Form.Group controlId="developerName" className="mb-3">
              <Form.Label>Developer Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter developer name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="border-primary"
              />
            </Form.Group>
            <Form.Group controlId="developerImage" className="mb-3">
              <Form.Label>Developer Image (Optional)</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                accept="image/*"
                className="border-primary"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={isLoading || isAddingOrEditing || isDeleting}
              className="w-100"
            >
              {editingDeveloper ? 'Update Developer' : 'Add Developer'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Developer;
