import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import dltIcon from "../../../Assets/deleteIcon.svg";
import { Spinner } from "react-bootstrap";
import * as formik from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import { useState,useEffect } from "react";

const SubForm = ({
  show,
  onHide,
  property,
  createProperty,
  setProperty,
  images,
  setImages,
  edit,
  deleteImage,
  setEdit,
  loading,
  setLoading
}) => {
  const [description, setDescription] = useState(property.description);
  const [neighborhood, setNeighborhood] = useState(property.neighborhood);
  useEffect(() => {
    setProperty({ ...property, description: description, neighborhood: neighborhood });
  }, [description, neighborhood]);

  useEffect(() => {
    setDescription(property.description);
    setNeighborhood(property.neighborhood);
  }, [property.description, property.neighborhood]);
  
  const renderExistingImages = () => {
    return (
      <div className="images-parent">
        {edit === true &&
          images.map((image, i) => (
            <div key={image.public_id} className="edit-image-container">
              <img key={image.url}
                src={dltIcon}
                onClick={() => deleteImage(image.public_id, property._id)}
                alt="delete"
                className="image-dlt-icon"
              />
              <img
                className="edit-form-image"
                src={image.url}
                alt="random"
                key={i}
              />
            </div>
          ))}
      </div>
    );
  };
  const { Formik } = formik;
  const schema = Yup.object().shape({
    name: Yup.string().required().min(6),
    appartement_type: Yup.string().required('apartment type is required'),
    size: Yup.number().required().typeError("size must be a number"),
    bedrooms: Yup.number().required().typeError("bedrooms must be a number"),
    bathrooms: Yup.number().required().typeError("bathrooms must be a number"),
    parkings: Yup.number().required().typeError("parkings must be a number"),
    price_low: Yup.number().required().typeError("price must be a number"),
    price_high: Yup.number().required().typeError("price must be a number"),
    unit_type: Yup.string().required('unit type is required'),
    payment_plan: Yup.string().required('payment plan is required'),
    category: Yup.string().required(),
  });
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setProperty({
      ...property,
      [name]: value,
    });
  };
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages(selectedImages);
  };
  const propertyOptions = [
    "Apartment",
    "Land",
    "Penthouse",
    "Townhouse",
    "Villa",
    "Office space",
    "Retail",
    "Shop",
    "Show room",
    "Business center",
    "Warehouse",
    "Plot",
    "Studio"
  ];
  const categoryOptions=[
    'Residential',
    'Commercial'
  ]

  return (
    <>
      <Modal className="modal-open" show={show} onHide={onHide} centered>
        <Formik
          validationSchema={schema}
          initialValues={property}
          onSubmit={(values) => {
            if (
           values
            ) {
              setLoading(true)
              createProperty();
            }
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Modal.Header>
                <Modal.Title>Add Property</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="modal-body"
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              >
                {edit === true ? renderExistingImages() : ""}
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Images</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="file"
                    onChange={handleImageChange}
                    accept=".png, .jpg, .jpeg"
                    multiple
                  />
                </Form.Group>
                <Form.Group controlId="validationFormik02">
                  <Form.Label>Property name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik14">
                  <Form.Label>Minimum Price</Form.Label>
                  <Form.Control
                    type="text"
                    name="price_low"
                    value={values.price_low}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.price_low && !errors.price_low}
                    isInvalid={!!errors.price_low}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.price_low}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik06">
                <Form.Label>Maximum Price</Form.Label>
                <Form.Control
                  type="text"
                  name="price_high"
                  value={values.price_high}
                  onChange={(event) => {
                    handleChange(event);
                    handleInputChange(event);
                  }}
                  isValid={touched.price_high && !errors.price_high}
                  isInvalid={!!errors.price_high}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price_high}
                </Form.Control.Feedback>
              </Form.Group>
                <Form.Group controlId="validationFormik03">
                  <Form.Label> Apartment type</Form.Label>
                  <Form.Select
                    name="appartement_type"
                    value={values.appartement_type}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={
                      touched.appartement_type && !errors.appartement_type
                    }
                    isInvalid={!!errors.appartement_type}
                  >
                    <option>
                      {property.appartement_type || "Choose Apartment type"}
                    </option>
                    {propertyOptions.map((option, i) => (
                      <option key={i} name="appartement_type" value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.appartement_type}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='validationFormik04'>
                  <Form.Label>Unit Type</Form.Label>
                  <Form.Control
                    type='text'
                    name='unit_type'
                    value={values.unit_type}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.unit_type && !errors.unit_type}
                    isInvalid={!!errors.unit_type}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.unit_type}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='validationFormik05'>
                  <Form.Label>Payment Plan</Form.Label>
                  <Form.Control
                    type='text'
                    name='payment_plan'
                    value={values.payment_plan}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.payment_plan && !errors.payment_plan}
                    isInvalid={!!errors.payment_plan}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.payment_plan}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik07">
                  <Form.Label>Size</Form.Label>
                  <Form.Control
                    pattern="[0-9.]+"
                    type="text"
                    name="size"
                    value={values.size}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.size && !errors.size}
                    isInvalid={!!errors.size}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.size}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik08">
                  <Form.Label>Bedrooms</Form.Label>
                  <Form.Control
                    type="text"
                    name="bedrooms"
                    value={values.bedrooms}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.bedrooms && !errors.bedrooms}
                    isInvalid={!!errors.bedrooms}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bedrooms}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik09">
                  <Form.Label>Bathrooms</Form.Label>
                  <Form.Control
                    type="text"
                    name="bathrooms"
                    value={values.bathrooms}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.bathrooms && !errors.bathrooms}
                    isInvalid={!!errors.bathrooms}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bathrooms}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik10">
                  <Form.Label>Parkings</Form.Label>
                  <Form.Control
                    type="text"
                    name="parkings"
                    value={values.parkings}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                    isValid={touched.parkings && !errors.parkings}
                    isInvalid={!!errors.parkings}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.parkings}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationFormik04">
                <Form.Label> Category</Form.Label>
                <Form.Select
                name="category"
                value={values.category}
                onChange={(event) => {
                  handleChange(event);
                  handleInputChange(event);
                }}
                isValid={
                  touched.category && !errors.category
                }
                isInvalid={!!errors.category}
              >
                <option>
                  {property.category || "Choose Category"}
                </option>
                {categoryOptions.map((option, i) => (
                  <option key={i} name="category" value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.category}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='validationFormik05'>
                  <Form.Label>Handover Date</Form.Label>
                  <Form.Control
                    type='text'
                    name='hand_over_date'
                    value={values.hand_over_date}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="validationFormik11">
                  <Form.Label>Additional features</Form.Label>
                  <Form.Control
                    type="text"
                    name="additional_features"
                    value={values.additional_features}
                    onChange={(event) => {
                      handleChange(event);
                      handleInputChange(event);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="validationFormik13">
                <Form.Label>Description</Form.Label>
                <ReactQuill
                  value={description}
                  onChange={setDescription}
                  theme="snow"
                />
              </Form.Group>
                <Form.Group controlId="validationFormik13">
                <Form.Label>Neighborhood</Form.Label>
                <ReactQuill
                  value={neighborhood}
                  onChange={setNeighborhood}
                  theme="snow"
                />
              </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
                {loading && <Spinner animation="border" role="status" />}

                <Button type="submit" >add</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SubForm;
