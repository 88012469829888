import React, { useState, useEffect } from 'react';
import './lnews.css'; // You can modify this file to add specific styles
import axios from 'axios';
import { token } from '../../Hooks/UserHooks';
import { APP_CONFIG } from '../../config';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Lnews = () => {
  const baseUrl = `${APP_CONFIG.backendUrl}api/news`;
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getNews = async () => {
      try {
        const response = await axios.get(baseUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setNews(response.data?.news);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getNews();
  }, []);

  const handleNewsClick = (newsItem) => {
    navigate(`/news/${newsItem._id}`, { state: { news: newsItem } });
  };

  return (
    <section id='latest-news' className='latest-news-section'>
      <h2 className='latest-news-header'>Latest News</h2>
      <Container>
        {isLoading ? (
          <div className='text-center'>
            <Spinner animation='border' role='status'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Row>
            {news.length > 0 ? (
              news.map((article) => (
                <Col key={article._id} sm={12} md={6} lg={4} className='mb-4'>
                  <Card className='news-card'>
                    <div
                      onClick={() => handleNewsClick(article)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Card.Img
                        variant='top'
                        src={
                          article.thumbnail
                            ? article.thumbnail.url
                            : '/default-thumbnail.jpg'
                        }
                        alt={article.title}
                        className='news-thumbnail'
                        style={{ objectFit: 'cover' }}
                      />
                      <Card.Body>
                        <Card.Title>{article.title}</Card.Title>
                      </Card.Body>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              <Col className='text-center'>
                <p>No news available at the moment.</p>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </section>
  );
};

export default Lnews;
