import React from 'react';
import './index.css';

const SkeletonLoader = () => {
  return (
    <div className="singlepage-classname-singlepage">
      <div className="singlepage-classname-left">
        <div className="singlepage-classname-image"></div>
      </div>
      <div className="singlepage-classname-description">
        <div className="singlepage-classname-line singlepage-classname-title"></div>
        <div className="singlepage-classname-line singlepage-classname-subtitle"></div>
        <div className="singlepage-classname-details">
          <div style={{ display: 'flex' }}>
            <div className="singlepage-classname-detail" style={{ width: '20%', height: '40px' }}></div>
            <div className="singlepage-classname-detail" style={{ width: '40%', marginLeft: '5%', height: '40px' }}></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="singlepage-classname-detail" style={{ width: '20%' }}></div>
            <div className="singlepage-classname-detail" style={{ width: '30%', marginLeft: '5%' }}></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="singlepage-classname-detail" style={{ width: '20%' }}></div>
            <div className="singlepage-classname-detail" style={{ width: '50%', marginLeft: '5%' }}></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="singlepage-classname-detail" style={{ width: '20%' }}></div>
            <div className="singlepage-classname-detail" style={{ width: '60%', marginLeft: '5%' }}></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="singlepage-classname-detail" style={{ width: '20%', height: '35px' }}></div>
            <div className="singlepage-classname-detail" style={{ width: '30%', marginLeft: '5%', height: '35px' }}></div>          </div>
        </div>
        <div className="singlepage-classname-buttons">
        <div className="singlepage-classname-line singlepage-classname-data"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
