import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, Button, Spinner } from 'react-bootstrap';
import { token } from '../../Hooks/UserHooks';
import { APP_CONFIG } from '../../config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const NewsForm = ({ news, onSuccess, onCancel }) => {
  const [title, setTitle] = useState(news?.title || '');
  const [description, setDescription] = useState(news?.description || '');
  const [thumbnail, setThumbnail] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!title || !description) {
      toast.error('Title and description are required.');
      setIsSubmitting(false);
      return;
    }

    if (!news && !thumbnail) {
      toast.error('Thumbnail is required for adding news.');
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description); 
    if (thumbnail) formData.append('thumbnail', thumbnail);

    try {
      if (news) {
        await axios.put(`${APP_CONFIG.backendUrl}api/news/${news._id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('News updated successfully');
      } else {
        await axios.post(`${APP_CONFIG.backendUrl}api/news`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('News added successfully');
      }
      onSuccess();
    } catch (error) {
      toast.error('Error saving news. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="newsTitle" className="mb-3">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter news title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="newsDescription" className="mb-3">
        <Form.Label>Description</Form.Label>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={setDescription}
          placeholder="Enter news description"
          className="mb-3"
          required
        />
      </Form.Group>
      <Form.Group controlId="newsThumbnail" className="mb-3">
        <Form.Label>Thumbnail</Form.Label>
        <Form.Control
          type="file"
          onChange={(e) => setThumbnail(e.target.files[0])}
          accept="image/*"
          required={!news}
        />
      </Form.Group>
      <Button type="submit" variant="primary" className="w-100" disabled={isSubmitting}>
        {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Save News'}
      </Button>
      <Button
        variant="secondary"
        className="w-100 mt-2"
        onClick={onCancel}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
    </Form>
  );
};

export default NewsForm;
