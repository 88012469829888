import React from "react";
import "./usersPage.css";
import { useState, useEffect,useReducer } from "react";
import axios from "axios";
import UserForm from "./userForm";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { showToast } from "../../../Utils/showToast";
import { token } from '../../../Hooks/UserHooks.js'
import { APP_CONFIG } from "../../../config.js";
const UsersPage = () => {
  const baseUrl = `${APP_CONFIG.backendUrl}api/users`;
  //save users data inside state
  const [data, setData] = useState("");
  const [search, setSearch] = useState({ search: "" });
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({name:"",email:"",password:""});
  const [refresh, setRefresh] = useReducer((x) => x + 1, 0);
  // Function to make the GET request using Axios
  
  //handle input data
  function handleInputChange(e) {
    let { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  }
  const handleClose = () => {
    if (show === true) {
      setShow(false);
    }
  };
  //ensure data is accurate when using search
  useEffect(() => {
    const getUsers = async () => {
      try {
        await axios
          .get(`${baseUrl}/all`, {
            params: { search },
            headers: { "Content-Type": "application/json" ,Authorization: `Bearer ${token}`},
          })
          .then((response) => setData(response.data));
      } catch (error) {
        // Handle any errors that might occur during the request
        console.error("Error fetching users:", error.message);
      }
    };
    getUsers();
  }, [search,refresh]);

  async function deleteUser(rowData) {
    try {
      await axios
        .delete(`${baseUrl}/delete/${rowData._id}`, {
          headers: { "Content-Type": "application/json",Authorization: `Bearer ${token}`},
        })
        .then((response) =>{
          if(response.status===200){
            showToast(response.data,"success")
            setRefresh()
          }
        });
      // Do something with the users data (e.g., log it or process it further)
    } catch (error) {
      // Handle any errors that might occur during the request
      console.error("Error fetching users:", error.message);
    }
  }
  async function createUser(){
    try {
      await axios
      .post(baseUrl, user,{
        headers: { "Content-Type": "application/json",Authorization: `Bearer ${token}`, }, 
      })
      .then((response) =>{
        if(response.status===201){
          setUser("")
          setShow(false)
          setRefresh()
          showToast(response.data,"success") 
        }else if(response.status===409){
          showToast(response.data,"error")
        }else{
          showToast("Something went wrong please try again later","error")
        }
      });
    } catch (error) {
      showToast("Something went wrong please try again later","error")
    }
  }
  const dateTemplate = (rowData) => {
    return (
      <div>
        <button onClick={() => deleteUser(rowData)} className="btn btn-primary">Delete</button>
      </div>
    );
  };
  return (
    <>
      <div className="users-page-parent">
        <div className="users-page-container">
          <h1 className="users-page-title">Users</h1>
          <div className="users-page-header-container">
            <input type="text" name="search" placeholder="Search by name" onChange={handleInputChange} />
            <button onClick={() => setShow(true)} className="btn btn-primary">Add</button>
          </div>
          <div>
            <DataTable
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 15]}
              style={{ height: "600px" }}
              value={data}
            >
              <Column
                headerStyle={{ backgroundColor: "#80adbb", color: "white" }}
                style={{ width: "25%" }}
                field="_id"
                header="id"
              ></Column>
              <Column
                style={{ width: "25%" }}
                field="name"
                header="Name"
              ></Column>
              <Column
                headerStyle={{ backgroundColor: "#80adbb", color: "white" }}
                style={{ width: "25%" }}
                field="email"
                header="Email"
              ></Column>
              <Column
                style={{ width: "25%" }}
                header="Actions"
                body={dateTemplate}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <UserForm show={show} onHide={handleClose} user={user} setUser={setUser} createUser={createUser}  />
    </>
  );
};

export default UsersPage;
