import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import dltIcon from '../../../Assets/deleteIcon.svg';
import { Spinner } from 'react-bootstrap';
import * as formik from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactQuill from 'react-quill';
import { useState, useEffect } from 'react';

const OffPlanForm = ({
  show,
  onHide,
  property,
  createProperty,
  setProperty,
  images,
  setImages,
  edit,
  deleteImage,
  setEdit,
  loading,
  setLoading,
  developers,
}) => {
  const [description, setDescription] = useState(property.description);

  useEffect(() => {
    setProperty({ ...property, description: description });
  }, [description]);

  const renderExistingImages = () => {
    return (
      <div className='images-parent'>
        {edit === true &&
          images.map((image, i) => (
            <div key={i} className='edit-image-container'>
              <img
                src={dltIcon}
                onClick={() => deleteImage(image.public_id, property._id)}
                alt='delete'
                className='image-dlt-icon'
              />
              <img className='edit-form-image' src={image.url} alt='random' />
            </div>
          ))}
      </div>
    );
  };
  const { Formik } = formik;
  const schema = Yup.object().shape({
    name: Yup.string().required().min(6),
    category: Yup.string().required(),
    address: Yup.string().required('address is required'),
    availability_expected: Yup.string().required(
      'availability expected is required'
    ),
    location: Yup.string(),
    appartement_type: Yup.string().required('property type is required'),
    size: Yup.string().required('property size is required'),
    price: Yup.string().required('property price is required'),
    unit_type: Yup.string(),
    payment_plan: Yup.string(),
  });
  let categoryOptions = ['Residential', 'Commercial'];
  let apartmentTypeOptions = [
    'Apartment',
    'Penthouse',
    'Land',
    'Townhouse',
    'Villa',
    'Office space',
    'Retail',
    'Shop',
    'Show room',
    'Business center',
    'Warehouse',
    'Plot',
    'Studio',
  ];
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setProperty({
      ...property,
      [name]: value,
    });
  };
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages(selectedImages);
  };
  useEffect(() => {
    setDescription(property.description);
  }, [property.description]);

  return (
    <>
      <Modal className='modal-open' show={show} onHide={onHide} centered>
        <Formik
          validationSchema={schema}
          initialValues={property}
          onSubmit={(values) => {
            console.log(values);
            if (
              values.name &&
              values.category &&
              values.address &&
              values.availability_expected &&
              values.appartement_type &&
              values.size &&
              values.price
            ) {
              setLoading(true);
              createProperty();
            }
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => {
            console.log(values);
            console.log(errors);
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header>
                  <Modal.Title>Add Property</Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className='modal-body'
                  style={{
                    maxHeight: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                  }}
                >
                  {edit === true ? renderExistingImages() : ''}
                  <Form.Group controlId='validationFormik01'>
                    <Form.Label>Images</Form.Label>
                    <Form.Control
                      className='form-control'
                      type='file'
                      onChange={handleImageChange}
                      accept='.png, .jpg, .jpeg'
                      multiple
                    />
                  </Form.Group>
                  <Form.Group controlId='validationFormik02'>
                    <Form.Label>Property name</Form.Label>
                    <Form.Control
                      type='text'
                      name='name'
                      value={values.name}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik04'>
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      name='category'
                      value={values.category}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.category && !errors.category}
                      isInvalid={!!errors.category}
                    >
                      <option>{property.category || 'Choose Category'}</option>
                      {categoryOptions.map((option, i) => (
                        <option key={i} name='category' value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {errors.category}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik04'>
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      name='appartement_type'
                      value={values.appartement_type}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={
                        touched.appartement_type && !errors.appartement_type
                      }
                      isInvalid={!!errors.appartement_type}
                    >
                      <option>
                        {property.appartement_type || 'Choose Property Type'}
                      </option>
                      {apartmentTypeOptions.map((option, i) => (
                        <option key={i} name='appartement_type' value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {errors.category}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik05'>
                    <Form.Label>Developed By</Form.Label>
                    <Form.Select
                      name='developer'
                      value={values.developer}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                    >
                      <option value=''>Choose Developer</option>
                      {developers?.map((developer) => (
                        <option key={developer._id} value={developer._id}>
                          {developer.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId='validationFormik02'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type='text'
                      name='address'
                      value={values.address}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.address && !errors.address}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik02'>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type='text'
                      name='location'
                      value={values.location}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.location && !errors.location}
                      isInvalid={!!errors.location}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.location}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik02'>
                    <Form.Label>Expected availability</Form.Label>
                    <Form.Control
                      type='text'
                      name='availability_expected'
                      value={values.availability_expected}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={
                        touched.availability_expected &&
                        !errors.availability_expected
                      }
                      isInvalid={!!errors.availability_expected}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.availability_expected}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik14'>
                    <Form.Label>Price(in AED)</Form.Label>
                    <Form.Control
                      type='text'
                      name='price'
                      value={values.price}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.price && !errors.price}
                      isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.price}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik07'>
                    <Form.Label>Size (in sqft)</Form.Label>
                    <Form.Control
                      pattern='[0-9.]+'
                      type='text'
                      name='size'
                      value={values.size}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.size && !errors.size}
                      isInvalid={!!errors.size}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.size}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik04'>
                    <Form.Label>Unit Type</Form.Label>
                    <Form.Control
                      type='text'
                      name='unit_type'
                      value={values.unit_type}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.unit_type && !errors.unit_type}
                      isInvalid={!!errors.unit_type}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.unit_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik05'>
                    <Form.Label>Payment Plan</Form.Label>
                    <Form.Control
                      type='text'
                      name='payment_plan'
                      value={values.payment_plan}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                      isValid={touched.payment_plan && !errors.payment_plan}
                      isInvalid={!!errors.payment_plan}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.payment_plan}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='validationFormik05'>
                    <Form.Label>Handover Date</Form.Label>
                    <Form.Control
                      type='text'
                      name='hand_over_date'
                      value={values.hand_over_date}
                      onChange={(event) => {
                        handleChange(event);
                        handleInputChange(event);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId='validationFormik13'>
                    <Form.Label>Description</Form.Label>
                    <ReactQuill
                      value={description}
                      onChange={setDescription}
                      theme='snow'
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={onHide}>
                    Cancel
                  </Button>
                  {loading && <Spinner animation='border' role='status' />}
                  <Button type='submit'>Add</Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default OffPlanForm;
