import React from 'react'
import "./dashboardLoader.css"
import Logo from "../../Assets/OnlyLogo1.png"

const DahsboardLoader = () => {
  return (
    <div className='dashboard-loader-container'>
      <div className="loader-container">
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo rotating" />
        </div>
        <p className="first_loading_p">Processing request...</p>
        <p>Please wait</p>
      </div>
    </div>
  )
}

export default DahsboardLoader
