import React from 'react';
import './index.css';

const SkeletonLoader = () => {
  return (
    <div className="skeleton-wrapper">
  
   
  <div className="skeleton skeleton-carousel"></div>
  
  <div className="skeleton skeleton-header"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  <div className="skeleton skeleton-description"></div>
  
   
  <div className="skeleton-features">
    <div className="skeleton skeleton-feature"></div>
    <div className="skeleton skeleton-feature"></div>
    <div className="skeleton skeleton-feature"></div>
  </div>
</div>

  );
};

export default SkeletonLoader;
