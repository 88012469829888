import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Marquee from 'react-fast-marquee';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Partners.css';
import { APP_CONFIG } from '../../config';
import { token } from '../../Hooks/UserHooks';
import { showToast } from '../../Utils/showToast';

const Partners = () => {
  const [developers, setDevelopers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDevelopers();
  }, []);

  const fetchDevelopers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${APP_CONFIG.backendUrl}api/developer/with-logos`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDevelopers(response.data?.developers);
    } catch (error) {
      showToast('Error fetching developers: ' + error.message, 'error');
      console.error('Error fetching developers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='partners-main'>
      <h2>Our Trusted Partners</h2>
      <div>
        {isLoading ? (
          <div className='skeleton-logos'>
            {Array.from({ length: 8 }).map((_, index) => (
              <div key={index} className='skeleton-logo'></div>
            ))}
          </div>
        ) : developers.length > 0 ? (
          <Marquee gradient={false} speed={100}>
            {developers?.map((developer) => (
              <div key={developer._id} style={{ margin: '0 15px' }}>
                <img
                  src={developer?.logoUrl?.url}
                  alt={developer?.name}
                  className='partner-logo'
                />
              </div>
            ))}
          </Marquee>
        ) : (
          <div>No logos available.</div>
        )}
      </div>
    </div>
  );
};

export default Partners;
